import { SvgIcon } from "../../../designSystem";

const ItalyFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='513'
        height='342'
        viewBox='0 0 513 342'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_301_952)'>
          <path d='M342.001 -0.00878906H0V341.99H513V-0.00878906H342.001Z' fill='white' />
          <path d='M0 -0.00585938H170.996V341.998H0V-0.00585938Z' fill='#6DA544' />
          <path d='M342.004 -0.00585938H513V341.998H342.004V-0.00585938Z' fill='#D80027' />
        </g>
        <defs>
          <clipPath id='clip0_301_952'>
            <rect width='513' height='342' fill='white' transform='translate(0 -0.00585938)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default ItalyFlagIcon;
