import { createContext, useCallback, useContext, useState } from "react";
import { Operations, detailed_mode } from "./permissionconstant";

const PermissionContext = createContext();

export default PermissionContext;

export const PermissionProvider = function ({ children }) {
  const [permissions, setPermissions] = useState(detailed_mode);
  const updatePermissions = useCallback((_permissions) => {
    setPermissions(_permissions);
  }, []);
  const hasPermission = useCallback(
    (entity, section, operation) => {
      if (permissions) {
        const _entity = permissions.find((_permissions) => _permissions.entity === entity);
        if (!_entity) return false;
        if (_entity.operations.find((_operation) => _operation.operation === Operations.ALL)) {
          return true;
        }
        if (section && _entity.sections) {
          const entitySection = _entity.sections.find((_section) => _section.section === section);
          if (!entitySection) {
            return false;
          } else {
            return entitySection.operations.find(
              (_operation) =>
                _operation.operation === operation || _operation.operation === Operations.ALL
            );
          }
        } else {
          return _entity.operations.find((_operation) => _operation.operation === operation);
        }
      }
      return null;
    },
    [permissions]
  );
  return (
    <PermissionContext.Provider value={{ updatePermissions, permissions, hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = function () {
  const { permissions, updatePermissions, hasPermission } = useContext(PermissionContext);
  return { permissions, updatePermissions, hasPermission };
};
