import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openPolotno: false,
  currentStep: 1,
  templateId: "",
  templateOpt: "",
  brandOpt: "",
  aiBrandOpt: "",
  allBrandOpt: [],
  brandGenerated: false,
  manualMediaType: "",
  selectedTags: [],
  templateType: "generic",
  editTempId: "",
  publicTemplate: false,
  saveErrors: null
};

const setAllBrandOpt = (state, action) => {
  state.allBrandOpt = action.payload;
};
const setEditTempId = (state, action) => {
  state.editTempId = action.payload;
};
const setPublicTemplate = (state, action) => {
  state.publicTemplate = action.payload;
};
const setTemplateType = (state, action) => {
  state.templateType = action.payload;
};
const setSelectedTags = (state, action) => {
  state.selectedTags = action.payload;
};
const setManualMediaType = (state, action) => {
  state.manualMediaType = action.payload;
};
const setOpenPolotno = (state, action) => {
  state.openPolotno = action.payload;
};
const setBrandGenerated = (state, action) => {
  state.brandGenerated = action.payload;
};
const setTemplateId = (state, action) => {
  state.templateId = action.payload;
};
const setTemplateOpt = (state, action) => {
  state.templateOpt = action.payload;
};
const setBrandOpt = (state, action) => {
  state.brandOpt = action.payload;
};
const setAiBrandOpt = (state, action) => {
  state.aiBrandOpt = action.payload;
};
const setCurrentStep = (state, action) => {
  state.currentStep = action.payload;
};

const increaseCurrentStep = (state) => {
  state.currentStep += 1;
};

const decreaseCurrentStep = (state) => {
  state.currentStep -= 1;
};

const setSaveErrors = (state, action) => {
  state.saveErrors = action.payload;
};

const customPdfStates = createSlice({
  name: "customPdfStates",
  initialState,
  reducers: {
    setAllBrandOpt,
    setTemplateType,
    setOpenPolotno,
    setEditTempId,
    increaseCurrentStep,
    decreaseCurrentStep,
    setTemplateId,
    setTemplateOpt,
    setBrandOpt,
    setPublicTemplate,
    setAiBrandOpt,
    setBrandGenerated,
    setCurrentStep,
    setManualMediaType,
    setSelectedTags,
    setSaveErrors,
    resetStates: () => initialState
  }
});

export default customPdfStates.reducer;
