import * as React from "react";
// import Button, { ButtonProps } from "@mui/material/Button";
import Slider, { SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
// import { Padding } from "@mui/icons-material";
// import newTheme from "./NewTheme";

interface CustomSliderProps extends SliderProps {
  // Add any additional props if needed
}

const PredisSlider = styled(Slider)<CustomSliderProps>(({ theme }) => ({
  "&.MuiSlider-root": {
    paddingBottom: "0px",
    padding: "11px 0",
    marginBottom: "0",
    marginTop: "0"
  },
  "& .MuiSlider-markLabel": {
    top: "18px"
  },
  "& .MuiSlider-thumb": {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "20px",
    height: "20px",
    boxShadow: `0px 2px 4px 0px rgba(0 0 0 / 25%)`
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: theme.palette.primary.main
  },
  " & .MuiSlider-rail": {
    backgroundColor: theme.palette.divider
  }
}));

const StyledSlider: React.FC<CustomSliderProps> = (props) => {
  return <PredisSlider {...props}></PredisSlider>;
};

export default StyledSlider;
