import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_ONBOARDING_PALETTE } from "../../../utils/constants";
import { localStorageSafe } from "../../../utils/localStorageUtils";

let initialState = {
  logo_url: "",
  idea: "",
  palette: [],
  type: ""
};

try {
  initialState = {
    logo_url: "",
    idea: "",
    palette: [],
    type: localStorageSafe("getItem", "onboarding_version") || "A"
  };
} catch (error) {}

const setType = (state, action) => {
  state.type = action.payload;
};
const setLogo = (state, action) => {
  state.logo_url = action.payload;
};
const setIdea = (state, action) => {
  state.idea = action.payload;
};
const addToPalette = (state) => {
  if (state.palette.length < 3) {
    state.palette.push(DEFAULT_ONBOARDING_PALETTE[state.palette.length]);
  }
};
const changePaletteColor = (state, action) => {
  const payload = action.payload;
  state.palette[payload.index] = payload.color;
};

const setPalette = (state, action) => {
  state.palette = action.payload;
};

const removePaletteColor = (state, action) => {
  // console.log(state.palette.slice(0, action.payload))
  state.palette = state.palette
    .slice(0, action.payload)
    .concat(state.palette.slice(action.payload + 1));
  // console.log(state.palette.slice(action.payload + 1,))
};
const onboardingStates = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setLogo: setLogo,
    setIdea: setIdea,
    addToPalette: addToPalette,
    changePaletteColor: changePaletteColor,
    removePaletteColor: removePaletteColor,
    setPalette: setPalette,
    setType
  }
});

export default onboardingStates.reducer;
