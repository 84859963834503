import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";
// import { Padding } from "@mui/icons-material";
// import newTheme from "./NewTheme";

interface CustomButtonProps extends ButtonProps {
  // Add any additional props if needed
}

const PredisButton = styled(Button)<CustomButtonProps>(({ theme }) => ({
  borderRadius: "8px",
  textTransform: "none",
  "& .MuiButton-outlined": {
    borderColor: "#DADBE0",
    // color: theme.palette.fontColor.font2,
    "&:hover": {
      borderColor: "#DADBE0",
      background: theme?.palette?.neutralColors?.[25] // will use theme.palette color
    },
    "& .MuiButton-startIcon": {
      margin: "0 8px 0 0" // Adjust margin for the start icon
    }
  },
  "&.MuiButton-contained": {
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.light
    },
    "&:focus": {
      //   boxShadow: "0 0 0 3px rgba(255, 138, 138, 0.4)"
    },
    "&:active": {
      background: theme.palette.primary.dark
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      pointerEvents: "none",
      background: "#C0C4CE"
    }
  },
  "&.MuiButton-text": {
    // color: theme.palette.primarySecondary.main,
    // borderColor: theme.palette.primarySecondary.main,
    // padding: "1rem",
    "& .MuiButton-startIcon": {
      margin: "0 8px 0 0" // Adjust margin for the start icon
    },
    "&:hover": {
      background: theme.palette?.neutralColors?.[25]
    }
  }
  // "& .MuiButton-startIcon": {
  //   margin: "0 0 0 0" // Adjust margin for the start icon
  // }
}));

const StyledCustomization: React.FC<CustomButtonProps> = (props) => {
  // return onlyIcon ? <PredisButton {...props} /> : <PredisButton {...props}>{text}</PredisButton>;
  return <PredisButton {...props}></PredisButton>;
};

export default StyledCustomization;
