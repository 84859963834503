import { SvgIcon } from "../../../designSystem";

const WorldFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='1000'
        height='600'
        viewBox='0 0 1000 600'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_1921_94)'>
          <path d='M1000 0H0V600H1000V0Z' fill='#0000CC' />
          <path
            d='M500 490C604.934 490 690 404.934 690 300C690 195.066 604.934 110 500 110C395.066 110 310 195.066 310 300C310 404.934 395.066 490 500 490Z'
            stroke='white'
            stroke-width='20'
          />
          <path
            d='M375.487 428.363C413.344 406.507 456.287 395 500 395C543.713 395 586.656 406.507 624.513 428.363'
            stroke='white'
            stroke-width='20'
            stroke-linecap='square'
          />
          <path
            d='M624.513 171.637C586.656 193.493 543.713 205 500 205C456.287 205 413.344 193.493 375.487 171.637'
            stroke='white'
            stroke-width='20'
            stroke-linecap='square'
          />
          <path
            d='M499.569 489.676C470.195 467.542 446.364 438.887 429.953 405.972C413.542 373.057 405 336.78 405 300C405 263.221 413.542 226.943 429.953 194.028C446.364 161.113 470.195 132.459 499.569 110.324'
            stroke='white'
            stroke-width='20'
          />
          <path d='M500 110V490' stroke='white' stroke-width='20' />
          <path d='M310 300H690' stroke='white' stroke-width='20' />
          <path
            d='M500.431 110.324C529.805 132.458 553.636 161.113 570.047 194.028C586.458 226.943 595 263.22 595 300C595 336.779 586.458 373.056 570.047 405.972C553.636 438.887 529.805 467.541 500.431 489.676'
            stroke='white'
            stroke-width='20'
          />
        </g>
        <defs>
          <clipPath id='clip0_1921_94'>
            <rect width='1000' height='600' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default WorldFlagIcon;
