import Dialog, { DialogProps } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import React from "react";

// Extend the DialogProps interface from MUI
interface CustomDialogProps extends DialogProps {
  // Add any additional props if needed
}

// Create a styled dialog
const PredisDialog = styled(Dialog)<CustomDialogProps>(({ theme, fullScreen }) => ({
  "& .MuiPaper-root": {
    borderRadius: "16px",
    padding: "1rem"
  },
  "& .MuiDialog-paper": {
    marginTop: fullScreen && 0, // for full screen dialogs
    borderRadius: fullScreen ? "0" : "16px"
  },
  ".MuiDialog-container": {
    alignItems: "baseline"
  },
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,0,0.4)"
  }
}));

const StyledDialog: React.FC<CustomDialogProps> = (props) => {
  return <PredisDialog {...props} />;
};

export default StyledDialog;
