import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

const PredisInputField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    "& fieldset": {
      borderColor: theme.palette.divider // Using the divider color from theme
    },
    "&:hover fieldset": {
      borderColor: "#DFE1E7" // Hover color
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main // Focus color
    }
  },
  "& .MuiInputBase-input": {
    // padding: "10px 15px" // Add padding to the input
    padding: "8px"
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
    color: theme.palette.text.secondary // Adjusted to use a more standard text color from the theme
  }
}));

// interface CustomInputFieldProps extends TextFieldProps {
//   helperText?: React.ReactNode;
// }

const StyledInputField: React.FC<TextFieldProps> = ({ helperText, ...props }) => {
  return (
    <PredisInputField
      variant='outlined'
      helperText={
        <>
          {helperText && props?.error && <WarningIcon sx={{ fontSize: "0.75rem", mr: 0.5 }} />}
          {helperText}
        </>
      }
      {...props}
    />
  );
};

export default StyledInputField;
