import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dropDateTime: "",
  fromCalender: false,
  calenderPrevOpen: false,
  calenderEditorOpen: false,
  calenderNewPostOpen: false,
  refetchSidebar: false,
  activePostData: null,
  calendarClickedDate: ""
};

const setActivePostData = (state, action) => {
  state.activePostData = action.payload;
};
const setDropDateTime = (state, action) => {
  state.dropDateTime = action.payload;
};
const setFromCalender = (state, action) => {
  state.fromCalender = action.payload;
};
const setCalenderPrevOpen = (state, action) => {
  state.calenderPrevOpen = action.payload;
};
const setCalenderEditorOpen = (state, action) => {
  state.calenderEditorOpen = action.payload;
};
const setCalenderNewPostOpen = (state, action) => {
  state.calenderNewPostOpen = action.payload;
};
const setRefetchSidebar = (state, action) => {
  state.refetchSidebar = action.payload;
};
const setCalendarClickedDate = (state, action) => {
  state.calendarClickedDate = action.payload;
};
const calenderSlice = createSlice({
  name: "calenderStates",
  initialState,
  reducers: {
    setDropDateTime,
    setFromCalender,
    setCalenderEditorOpen,
    setCalenderPrevOpen,
    setCalenderNewPostOpen,
    setRefetchSidebar,
    setActivePostData,
    setCalendarClickedDate
  }
});

export default calenderSlice.reducer;
