import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatHistory: { byId: {}, allIds: [] },
  activeChatId: "",
  allChats: { byId: {}, allIds: [] },
  isLimitReached: false,
  inputValue: "",
  createPostOpen: false,
  createPostIdeaVal: "",
  chatsSwitching: false
};

const setChatHistory = (state, action) => {
  const data = action.payload;
  state.chatHistory.allIds = [];
  data.forEach((msg) => {
    state.chatHistory.allIds.push(msg.id);
    state.chatHistory.byId[msg.id] = msg;
  });
};
const clearChatHistory = (state) => {
  state.chatHistory.allIds = [];
};
const setAllChats = (state, action) => {
  const data = action.payload;
  state.allChats.allIds = [];
  data.forEach((chat) => {
    state.allChats.allIds.push(chat._id);
    state.allChats.byId[chat._id] = chat;
  });
};

const setCreatePostIdeaVal = (state, action) => {
  state.createPostIdeaVal = action.payload;
};
const setChatsSwitching = (state, action) => {
  state.chatsSwitching = action.payload;
};
const setCreatePostOpen = (state, action) => {
  state.createPostOpen = action.payload;
};
const setLimitReached = (state, action) => {
  state.isLimitReached = action.payload;
};
const setInputValue = (state, action) => {
  state.inputValue = action.payload;
};

const setOldChatHistory = (state, action) => {
  const data = action.payload;
  // state.chatHistory.allIds = [];
  data.forEach((msg) => {
    state.chatHistory.allIds.unshift(msg.id);
    state.chatHistory.byId[msg.id] = msg;
  });
};

const addNewMessage = (state, action) => {
  const newMsg = action.payload;
  state.chatHistory.allIds.push(newMsg.id);
  state.chatHistory.byId[newMsg.id] = newMsg;
};

const setActiveChatId = (state, action) => {
  state.activeChatId = action.payload;
};

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    setChatHistory,
    addNewMessage,
    setActiveChatId,
    setOldChatHistory,
    setAllChats,
    setLimitReached,
    setInputValue,
    setCreatePostOpen,
    setCreatePostIdeaVal,
    setChatsSwitching,
    clearChatHistory
  }
});

export default chatSlice.reducer;
