import ListItem, { ListItemProps } from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import React from "react";

// Extend the ListItemProps interface from MUI
interface CustomListItemProps extends ListItemProps {
  // Add any additional props if needed
}

// Create a styled dialog
const PredisListItem = styled(ListItem)<CustomListItemProps>(({ theme }) => ({
  "&.MuiListItem-root": {
    borderRadius: "6px",
    color: theme.palette.primarySecondary?.main,
    // gap: "6px",
    // fontSize: "13px",
    padding: "2px 12px 2px 12px"
  },
  "& .MuiListItemButton-root": {
    borderRadius: "8px",
    // height: "32px",
    "&.Mui-selected": {
      backgroundColor: "#E8F0FF",
      "& .MuiListItemText-root": {
        fontWeight: "700"
      }
    }
  }
}));

const StyledListItem: React.FC<CustomListItemProps> = (props) => {
  return <PredisListItem {...props} />;
};

export default StyledListItem;
