import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  common: { force_regenerate: false, gen_palette: "", post_type_pref: {} },
  businessPost: {
    ownIdea: false,
    postIdea: "",
    postType: "",
    business_media_type: "single_image",
    business_input_language: "",
    business_output_language: "",
    business_video_duration: ""
  },
  specialDayPost: {
    special_day_id: "",
    special_day_n_posts: "",
    isSpecialDayPost: false
  },
  quotesPost: { quoteData: {} },
  scriptHeadlines: {
    fromScript: false,
    headlines: []
  },
  eComPost: {
    eCom_media_type: "", // redundant, business_media_type is used in ecom as well
    eCom_video_duration: "",
    asset_platform: "",
    asset_ids: [],
    ecom_inpLang: "",
    ecom_outLang: "",
    ecom_asset_idx: "",
    ecomTemplate: "static"
  },
  isMeme: false,
  isBlogPost: false,
  ttsDetails: {}
};
const setSpecialDayPost = (state, action) => {
  state.specialDayPost.isSpecialDayPost = action.payload;
};
const setIsMeme = (state, action) => {
  state.isMeme = action.payload;
};
const setFromScript = (state, action) => {
  state.scriptHeadlines.fromScript = action.payload;
};
const setScriptHeadlines = (state, action) => {
  state.scriptHeadlines.headlines = action.payload;
};
const setBusinessPost = (state, action) => {
  state.businessPost[action.property] = action.payload;
};
const setCommonForceRegenerate = (state, action) => {
  state.common.force_regenerate = action.payload;
};
const setCommonGenPalette = (state, action) => {
  state.common.gen_palette = action.payload;
};
const setCommonPostTypePref = (state, action) => {
  state.common.post_type_pref = action.payload;
};

const setBusinessOwnIdea = (state, action) => {
  state.businessPost.ownIdea = action.payload;
};
const setBusinessPostIdea = (state, action) => {
  state.businessPost.postIdea = action.payload;
};
const setBusinessMediaType = (state, action) => {
  state.businessPost.business_media_type = action.payload;
};
const setBusinessInputLanguage = (state, action) => {
  state.businessPost.business_input_language = action.payload;
};
const setBusinessOutputLanguage = (state, action) => {
  state.businessPost.business_output_language = action.payload;
};
const setBusinessVideoDuration = (state, action) => {
  state.businessPost.business_video_duration = action.payload;
};
const setSpecialDayId = (state, action) => {
  state.specialDayPost.special_day_id = action.payload;
};
const setSpecialDayNPost = (state, action) => {
  state.specialDayPost.special_day_n_posts = action.payload;
};
const setQuotesData = (state, action) => {
  state.quotesPost.quoteData = action.payload;
};
const setEComMediaType = (state, action) => {
  state.eComPost.eCom_media_type = action.payload;
};
const setEComVideoDuration = (state, action) => {
  state.eComPost.eCom_video_duration = action.payload;
};
const setEComAssetPlatform = (state, action) => {
  state.eComPost.asset_platform = action.payload;
};
const setEcomTemplate = (state, action) => {
  state.eComPost.ecomTemplate = action.payload;
};
const setEComAssetIds = (state, action) => {
  state.eComPost.asset_ids = action.payload;
};
const setEComInputLang = (state, action) => {
  state.eComPost.ecom_inpLang = action.payload;
};
const setEComOutputLang = (state, action) => {
  state.eComPost.ecom_outLang = action.payload;
};
const setEComAssetIdX = (state, action) => {
  state.eComPost.ecom_asset_idx = action.payload;
};
const setIsBlogPost = (state, action) => {
  state.isBlogPost = action.payload;
};

const setTTSDetails = (state, action) => {
  state.ttsDetails = action.payload;
};

const newPostConfigSlice = createSlice({
  name: "newPostConfig",
  initialState,
  reducers: {
    setFromScript,
    setSpecialDayPost,
    force_regenerate: setCommonForceRegenerate,
    gen_palette: setCommonGenPalette,
    post_type_pref: setCommonPostTypePref,
    ownIdea: setBusinessOwnIdea,
    postIdea: setBusinessPostIdea,
    businessMedia_type: setBusinessMediaType,
    businessInput_language: setBusinessInputLanguage,
    businessOutput_language: setBusinessOutputLanguage,
    businessVideo_duration: setBusinessVideoDuration,
    special_day_id: setSpecialDayId,
    special_day_n_posts: setSpecialDayNPost,
    quoteData: setQuotesData,
    eComMedia_type: setEComMediaType,
    eComVideo_duration: setEComVideoDuration,
    asset_platform: setEComAssetPlatform,
    asset_ids: setEComAssetIds,
    ecom_inpLang: setEComInputLang,
    ecom_outLang: setEComOutputLang,
    ecom_asset_idx: setEComAssetIdX,
    setEcomTemplate,
    setBusinessPost,
    setIsMeme,
    setScriptHeadlines,
    setIsBlogPost,
    setTTSDetails,
    resetConfig: () => initialState
  }
});

export default newPostConfigSlice.reducer;
