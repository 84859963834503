import Card, { CardProps } from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import * as React from "react";

interface CustomCardProps extends CardProps {
  // Add any additional props if needed
}

//will add more styling later
const PredisCard = styled(Card)<CustomCardProps>(({ theme }) => ({
  "&.MuiPaper-root.MuiCard-root": {
    boxShadow: "none",
    borderRadius: "8px"
    // "&:hover": {
    //   boxShadow: "0px 8px 16px 0px rgba(23, 27, 40, 0.15)"
    // }
  }
}));

const StyledCard: React.FC<CustomCardProps> = (props) => {
  return <PredisCard variant='outlined' {...props} />;
};

export default StyledCard;
