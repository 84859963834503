import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tagLoading: false,
  tagData: [],
  tagErrorMsg: "",
  postTagsData: {}
};

const setTagLoading = (state) => {
  state.tagLoading = true;
};
const setTagError = (state, action) => {
  state.tagLoading = false;
  state.tagErrorMsg = action.payload;
};
const setTagData = (state, action) => {
  state.tagLoading = false;
  state.tagData = action.payload;
  state.tagErrorMsg = "";
};
const changeSelectedTag = (state, action) => {
  let filter = state.tagData.filter((el) => el.name === action.payload)[0];
  filter.selected = !filter.selected;
};
const setPostTags = (state, action) => {
  const { ev_id, tagList } = action.payload;
  state.postTagsData[ev_id] = tagList;
};
const removeGlobalTags = (state, action) => {
  const { tagName } = action.payload;
  // Remove tag from tags array
  let removeTagName = state.tagData.find((el) => el.name === tagName).name;
  state.tagData = state.tagData.filter((el) => el.name !== removeTagName);
  // Remove tag from all posts containing the tag
  Object.keys(state.postTagsData).forEach((postId) => {
    state.postTagsData[postId] = state.postTagsData[postId].filter(
      (el) => el.name !== removeTagName
    );
  });
};
const getPostTagsSlice = createSlice({
  name: "getPostTags",
  initialState,
  reducers: {
    setTagLoading,
    setTagError,
    setTagData,
    changeSelectedTag,
    setPostTags,
    removeGlobalTags
  }
});

export default getPostTagsSlice.reducer;
