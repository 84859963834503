import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import React from "react";

// Extend the MenuItemProps interface from MUI
interface CustomMenuItemProps extends MenuItemProps {
  // Add any additional props if needed
}

// Create a styled dialog
const PredisMenuItem = styled(MenuItem)<CustomMenuItemProps>(({ theme }) => ({
  "&.MuiMenuItem-root": {
    borderRadius: "6px",
    color: theme.palette.primarySecondary?.main,
    gap: "6px",
    // fontSize: "13px",
    padding: "6px 8px 6px 8px",
    "&:hover": {
      backgroundColor: "#EBEFF5"
    }
  }
}));

const StyledMenuItem: React.FC<CustomMenuItemProps> = (props) => {
  return <PredisMenuItem {...props} />;
};

export default StyledMenuItem;
