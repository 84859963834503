import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";
// import WarningIcon from "@mui/icons-material/Warning";
// import newTheme from "./NewTheme";

const PredisSelect = styled(Select)<SelectProps>(({ theme, size }) => {
  return {
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
      //   background: "transparent"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "&.MuiOutlinedInput-root": {
      borderRadius: "8px",
      color: theme.palette?.secondary.main,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.divider,
      height: size === "small" ? "32px" : size === "medium" ? "39px" : "auto",
      fontSize: size === "small" ? "14px" : "auto"
    }
    // "&:focus": {
    //   background: "transparent"
    // },
    // "&:active": {
    //   background: "transparent"
    // }
  };
});

const StyledSelect: React.FC<SelectProps> = ({ ...props }) => {
  return (
    <PredisSelect
      {...props}
      IconComponent={props?.IconComponent ? props?.IconComponent : KeyboardArrowDownIcon}
      size={props?.size}
    />
  );
};

export default StyledSelect;
