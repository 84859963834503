import { SvgIcon } from "../../../designSystem";

const PortugeseFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='513'
        height='342'
        viewBox='0 0 513 342'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_301_463)'>
          <path d='M0 -0.00439453H513V341.988H0V-0.00439453Z' fill='#D80027' />
          <path d='M197.025 -0.00439453V341.988H0V-0.00439453H197.025Z' fill='#6DA544' />
          <path
            d='M197.025 235.117C232.44 235.117 261.15 206.407 261.15 170.992C261.15 135.577 232.44 106.867 197.025 106.867C161.61 106.867 132.9 135.577 132.9 170.992C132.9 206.407 161.61 235.117 197.025 235.117Z'
            fill='#FFDA44'
          />
          <path
            d='M160.952 138.93V179.009C160.952 198.93 177.101 215.079 197.022 215.079C216.943 215.079 233.092 198.93 233.092 179.009V138.93H160.952Z'
            fill='#D80027'
          />
          <path
            d='M197.022 191.031C190.392 191.031 184.999 185.638 184.999 179.008V162.977H209.046V179.008C209.045 185.637 203.651 191.031 197.022 191.031Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_301_463'>
            <rect width='513' height='342' fill='white' transform='translate(0 -0.00830078)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PortugeseFlagIcon;
