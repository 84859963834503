import { Box, LinearProgress, Typography } from "../../designSystem";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import mainStore from "../../MainStore/store";
import { we_set_user_attributes } from "../../commonUtils";
import { getCookie, store_user_details } from "../../utils/common";
import { REF_HISTORY_COOKIE_KEY } from "../../utils/constants";
import { getPath } from "../../utils/navigation";
import { logoutFn } from "../SignIn/logoutFn";
import { check_device, set_linked_handles } from "./HelperFunctions";

export function finishAppleLogin({ state, appId }, navigate) {
  const refCookie = getCookie("pred_ref");
  const urlCookie = getCookie("pref_ref_location");
  const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);
  let params = {
    state: state,
    referral: refCookie,
    user_timezone: mainStore.getState().globalReducer.userTimezone,
    device: check_device(),
    referral_url: urlCookie,
    is_mobile: mainStore.getState().uiStates.isWebView,
    referral_session_history: historyCookie
    // gpt_store_conv_id: session_Id
  };
  if (appId) {
    params.appId = appId;
  }
  let urlParams = new URLSearchParams(params);
  // set attribute
  we_set_user_attributes({ referral: refCookie, referral_url: urlCookie });
  axios
    .post(`/users/login_apple/`, urlParams)
    .then((res) => {
      store_user_details(
        res.data["username"],
        res.data["useremail"],
        res.data["user_img_url"],
        res.data,
        "apple"
      );
      set_linked_handles(res.data["handle_data"]);
      mainStore.dispatch({
        type: "userData/setLoginPlatform",
        payload: "apple"
      });
      try {
        localStorage.setItem("user_has_posts", res.data["user_has_posts"]);
      } catch (err) {
        console.log(err);
      }
      let navigateTo = "";
      if (res?.data?.next) {
        const url = new URL(res.data.next);
        navigateTo = url.pathname + url.search;
      } else {
        navigateTo = !res.data["user_has_posts"]
          ? res.data["onboarding_version"].startsWith("E")
            ? getPath("/onboarding-website-create")
            : "/onboarding/?user_domain=" + res?.data?.user_domain
          : getPath("/dashboard");
      }
      navigate(navigateTo);
    })
    .catch((err) => {
      console.log("error in google login - ", err);
      logoutFn(navigate, "/");
    });
}

const AppleOauth = (app_id) => {
  const navigate = useNavigate();

  useEffect(() => {
    var search_params = new URLSearchParams(window.location.search);
    let all_params = {};
    search_params.forEach(function (v, k) {
      all_params[k] = v;
    });
    const state = search_params.get("state");
    // const id_token = search_params.get("id_token");
    if (state) {
      // localStorage.setItem("google_oauth", JSON.stringify(all_params));
      finishAppleLogin({ state, appId: app_id }, navigate);
      // window.close();
    } else {
      console.log("invalid apple login");
      logoutFn(navigate, "/");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "200px",
        textAlign: "center"
      }}
    >
      <Typography variant={"body1"}>{t("loginPage.logginApple")}</Typography>
      <LinearProgress variant='indeterminate' />
    </Box>
  );
};

export default AppleOauth;
