import { createSlice } from "@reduxjs/toolkit";
import addMinutes from "date-fns/addMinutes";

const initPreviewPlatforms = {
  ig: {
    // Multiple can be true at a time.
    publish: false,
    disabled: true
  },
  igs: {
    publish: false,
    disabled: true
  },
  fb: {
    publish: false,
    disabled: true
  },
  fbr: {
    publish: false,
    disabled: true
  },
  li: {
    publish: false,
    disabled: true
  },
  pi: {
    publish: false,
    disabled: true
  },
  gmb: {
    publish: false,
    disabled: true
  },
  tt: {
    publish: false,
    disabled: true
  },
  // fbg: {
  //   publish: false,
  //   disabled: true
  // },
  twt: {
    publish: false,
    disabled: true
  },
  yt: {
    publish: false,
    disabled: true
  }
};
const initialState = {
  previewPlatforms: {
    ...initPreviewPlatforms
  },
  platformDateTime: {
    ig: addMinutes(new Date(), 2).toISOString(),
    igs: addMinutes(new Date(), 2).toISOString(),
    fb: addMinutes(new Date(), 2).toISOString(),
    fbr: addMinutes(new Date(), 2).toISOString(),
    li: addMinutes(new Date(), 2).toISOString(),
    pi: addMinutes(new Date(), 2).toISOString(),
    gmb: addMinutes(new Date(), 2).toISOString(),
    tt: addMinutes(new Date(), 2).toISOString(),
    // fbg: addMinutes(new Date(), 2).toISOString(),
    twt: addMinutes(new Date(), 2).toISOString(),
    yt: addMinutes(new Date(), 2).toISOString()
  },
  displayPlatform: "ig",
  sameTimeChecked: true,
  previewData: {},
  selectedEditButton: { value: "caption", active: false },
  fetchingData: true,
  selectedIds: {
    pi: [],
    gmb: []
    // fbg: []
  },
  gmbCTA: { value: "", link: "" },
  platformCreativeJson: {},
  ttAdditionalData: null,
  sharingHistory: [],
  isFromHistory: false,
  historyScheduleId: "",
  sendingEmail: false
};

const updatePlatformData = (state, action) => {
  if (state.previewPlatforms[action.platform])
    state.previewPlatforms[action.platform][action.property] = action.payload;
};
const setTtAdditionalData = (state, action) => {
  state.ttAdditionalData = action.payload;
};
const setSendingEmail = (state, action) => {
  state.sendingEmail = action.payload;
};
const setHistoryScheduleId = (state, action) => {
  state.historyScheduleId = action.payload;
};
const setIsFromHistory = (state, action) => {
  state.isFromHistory = action.payload;
};
const resetPreviewHistoryData = (state) => {
  state.previewData = {};
  state.selectedIds = {
    pi: [],
    gmb: []
    //fbg: []
  };
  state.previewPlatforms = initPreviewPlatforms;
};
const setSharingHistory = (state, action) => {
  state.sharingHistory = action.payload;
};
const setDisplayPlatform = (state, action) => {
  state.displayPlatform = action.payload;
};
const setSameTimeChecked = (state, action) => {
  state.sameTimeChecked = action.payload;
};
const setPreviewData = (state, action) => {
  state.previewData[action.platName] = action.payload;
};
const setSelectedEditButton = (state, action) => {
  state.selectedEditButton = action.payload;
};
const setFetchingData = (state, action) => {
  state.fetchingData = action.payload;
};
const updatePlatformDateTime = (state, action) => {
  state.platformDateTime[action.platName] = action.payload;
};
const updatePreviewData = (state, action) => {
  if (state.previewData[action.platName])
    state.previewData[action.platName][action.property] = action.payload;
};
const setSelectedIds = (state, action) => {
  state.selectedIds[action.platName] = action.payload;
};
const setGmbCta = (state, action) => {
  state.gmbCTA = action.payload;
};
const updateCreativeJson = (state, action) => {
  state.platformCreativeJson = {
    ...state.platformCreativeJson,
    ...action.payload
  };
};
const setPlatformDateTime = (state, action) => {
  state.platformDateTime = action.payload;
};
const resetPreviewPostStates = () => initialState;

const previewPostSlice = createSlice({
  name: "previewPostSlice",
  initialState,
  reducers: {
    setTtAdditionalData,
    setSharingHistory,
    updatePlatformData,
    setDisplayPlatform,
    setSameTimeChecked,
    setSendingEmail,
    setPreviewData,
    setSelectedEditButton,
    setFetchingData,
    updatePreviewData,
    updatePlatformDateTime,
    setSelectedIds,
    resetPreviewPostStates,
    setGmbCta,
    updateCreativeJson,
    setPlatformDateTime,
    resetPreviewHistoryData,
    setIsFromHistory,
    setHistoryScheduleId
  }
});

export default previewPostSlice.reducer;
