import axios from "axios";
import mainStore from "../../MainStore/store";
import {
  getCookie,
  getListenerParent,
  getMobileOperatingSystem,
  store_user_details
} from "../../utils/common";
import { EMAIL_REGEX, REF_HISTORY_COOKIE_KEY } from "../../utils/constants";
import { getPath } from "../../utils/navigation";
import { finishAppleLogin } from "./AppleOauth";
import { finishFBLogin } from "./FbOauth";
import { finishGoogleLogin } from "./GoogleOauth";

let windowObjectReference = null;
let previousUrl = null;

const openSignInWindow = (url, name, receiveMessage) => {
  // remove any existing event listeners
  window.removeEventListener("message", receiveMessage);

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  // add the listener for receiving a message from the popup
  window.addEventListener("message", (event) => receiveMessage(event), false);

  // assign the previous URL
  previousUrl = url;
};

export const changeEmail = (emailVal, setEmailVal, setValidErr) => {
  const disallowPlusRegex = /^[a-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  let isEmailValid = disallowPlusRegex.test(emailVal);
  if (!isEmailValid) {
    setValidErr((prev) => {
      return {
        ...prev,
        email: { error: true, msg: "alertComps.forgotPass.invalidMail" }
      };
    });
  } else {
    setValidErr((prev) => {
      return { ...prev, email: { error: false, msg: "" } };
    });
  }
  setEmailVal(emailVal);
};
export const signinChangeEmail = (emailVal, setEmailVal, setValidErr) => {
  let isEmailValid = EMAIL_REGEX.test(emailVal);
  if (!isEmailValid) {
    setValidErr((prev) => {
      return {
        ...prev,
        email: { error: true, msg: "alertComps.forgotPass.invalidMail" }
      };
    });
  } else {
    setValidErr((prev) => {
      return { ...prev, email: { error: false, msg: "" } };
    });
  }
  setEmailVal(emailVal);
};
export const changePass = (passVal, setPassVal, setValidErr) => {
  let isPassValid = passVal.length >= 8 && passVal.length <= 20;
  if (!isPassValid) {
    setValidErr((prev) => {
      return {
        ...prev,
        password: {
          error: true,
          msg: "signUpPage.passCharacters"
        }
      };
    });
  } else {
    setValidErr((prev) => {
      return { ...prev, password: { error: false, msg: "" } };
    });
  }
  setPassVal(passVal);
};
export const runValidations = (emailVal, passVal) => {
  let isEmailValid = EMAIL_REGEX.test(emailVal);
  let isPassValid = passVal.length >= 8 && passVal.length <= 20;
  return isEmailValid && isPassValid;
};
export const signUpFn = (emailVal, passVal, app_id) => {
  let validationsComplete = runValidations(emailVal, passVal);
  const { isWebView } = mainStore.getState().uiStates;
  return new Promise((resolve, reject) => {
    if (validationsComplete) {
      const search_params = new URLSearchParams(window.location.search);
      const refCookie = getCookie("pred_ref");
      const locationCookie = getCookie("pref_ref_location");
      const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);
      const sessionId = search_params.get("conv_id");

      let params = {
        mode: "create",
        url_params: window.location.search,
        referral: refCookie,
        user_referral_code: search_params.get("urc"),
        session_id: search_params.get("session_id"),
        referral_url: locationCookie,
        device: check_device(),
        app_id: app_id,
        referral_session_history: historyCookie,
        is_mobile: isWebView,
        ...(sessionId && sessionId !== "null" && { conv_id: sessionId })
      };
      let sendData = new URLSearchParams(params);
      axios
        .post("/users/authorize_login/", sendData, {
          headers: {
            Authorization: "Basic " + btoa(emailVal + ":" + passVal)
          }
        })
        .then((res) => {
          if (res.data.valid) {
            resolve(res.data.message);
          } else {
            reject(res.data.message);
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    } else {
      reject("Please fix Validation Errors");
      return;
    }
  });
};

const connect_login_socket = (token, platform, navigate) => {
  // deprecated
  const url_search_params = new URLSearchParams(window.location.search);
  const currCookie = getCookie("pred_ref");
  const params = new URLSearchParams({
    id_token: token,
    platform: platform,
    device: check_device(),
    url_params: window.location.search,
    referral: currCookie,
    user_timezone: mainStore.getState().globalReducer.userTimezone
  });

  return new Promise((resolve, reject) => {
    axios
      .get(`/users/loginUser/?${params.toString()}`)
      .then((data) => {
        const msg_data = data.data;
        if (msg_data["is_valid"] == true) {
          store_user_details(
            msg_data["username"],
            msg_data["useremail"],
            msg_data["user_img_url"],
            // msg_data["system_uid"],
            msg_data,
            platform
          );
          mainStore.dispatch({
            type: "userData/setLoginPlatform",
            payload: platform
          });
          try {
            localStorage.setItem("user_has_posts", msg_data["user_has_posts"]);
          } catch (err) {
            console.log(err);
          }
          // set_linked_handles(msg_data["handle_data"]);
          let navigateTo = !msg_data["user_has_posts"] ? "/onboarding" : getPath("/dashboard"); //redirect to new onboarding
          resolve(navigateTo);
        } else {
          reject(`Invalid ${platform} login - ${msg_data["error_msg"]}`);
        }
      })
      .catch((err) => reject(err.message));
  });
};
export const checkValueinArray = (valArr, checkArr) => {
  let n_available = 0;
  valArr.forEach((val) => {
    checkArr.forEach((valchk) => {
      if (val === valchk) {
        n_available += 1;
      }
    });
  });
  return n_available === valArr.length;
};
const signupRedirect = (e, resolve_uri) => {
  const { data } = e;
  return new Promise((resolve, reject) => {
    try {
      const [type, jsonData] = data.split("||");
      if (type === "linking") {
        const initParams = JSON.parse(jsonData);
        if (initParams === null) {
          reject("login failed");
          return;
        } else if (initParams?.error) {
          reject(initParams?.msg);
          return;
        }
        const params = new URLSearchParams(initParams);
        resolve(`${resolve_uri}&${params}`);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};
export const loginFacebook = (app_id, sessionId, navigate) => {
  return new Promise((resolve, reject) => {
    const pathQuery = new URLSearchParams(window.location.search);
    const ref = pathQuery.get("ref");
    const { isWebView } = mainStore.getState().uiStates;
    try {
      localStorage.setItem("facebook_ref", ref);
    } catch (error) {}
    axios
      .get(
        `/users/facebook_login_auth/?is_mobile=${isWebView}${
          app_id ? "&app_id=" + app_id : ""
        }&conv_id=${sessionId}`
      )
      .then((res) => {
        let state_id = res.data.state_id;
        try {
          localStorage.setItem("facebook_state_id", state_id);
        } catch (e) {}
        if (isWebView) {
          window?.ReactNativeWebView?.postMessage(`oauth ||${res?.data?.url}`);
          // create a wrapper function for removeeventlistener
          const wrapSignup = (e) => {
            signupRedirect(e, "/oauth/fb_login/?is_mobile=true")
              .then((d) => resolve(d))
              .catch((e) => reject(e))
              .finally((e) => getListenerParent().removeEventListener("message", wrapSignup));
          };

          getListenerParent().addEventListener("message", wrapSignup);
        } else {
          if (app_id) {
            openSignInWindow(res.data.url, "Facebook Login", (event) => {
              const data = event.data;
              data.params &&
                finishFBLogin({ ...data, sessionId: sessionId, appId: app_id }, navigate);
            });
          } else {
            window.location.href = res.data.url;
          }
        }
      })
      .catch((err) =>
        mainStore.dispatch({
          type: "uiStates/showAlert",
          alertType: "error",
          payload: err.message
        })
      );
  });
};
export function check_device() {
  var window_size = window.innerWidth;
  var device = "desktop";
  if (window_size < 576) {
    device = getMobileOperatingSystem();
  }
  return device;
}
export function set_linked_handles(active_handle_data) {
  var linked_handles = [];
  try {
    linked_handles = JSON.parse(localStorage.getItem("ig_linked_handles"));
  } catch (e) {}
  var job_id = mainStore.getState().globalReducer.jobId;
  if (linked_handles != undefined) {
    var handle_found = false;
    linked_handles.forEach((v) => {
      if (v["job_id"] == job_id) {
        handle_found = true;
      }
    });
    if (!handle_found && active_handle_data["linked"]) {
      linked_handles.push(active_handle_data);
      try {
        localStorage.setItem("ig_linked_handles", JSON.stringify(linked_handles));
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: linked_handles
        });
      } catch (e) {
        console.log("No localstorage Found for ig_linked_handles, Setting in redux...", e);
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: linked_handles
        });
      }
    }
  } else {
    if (active_handle_data["linked"]) {
      try {
        localStorage.setItem("ig_linked_handles", JSON.stringify([active_handle_data]));
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: [active_handle_data]
        });
      } catch (e) {
        console.log("No localstorage Found for ig_linked_handles, Setting in redux...", e);
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: [active_handle_data]
        });
      }
    } else {
      try {
        localStorage.setItem("ig_linked_handles", JSON.stringify([]));
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: []
        });
      } catch (e) {
        console.log("No localstorage Found for ig_linked_handles, Setting in redux...", e);
        mainStore.dispatch({
          type: "setPostingHandleData",
          payload: []
        });
      }
    }
  }
}

export const loginGoogle = (app_id, sessionId, navigate) => {
  return new Promise((resolve, reject) => {
    const pathQuery = new URLSearchParams(window.location.search);
    const ref = pathQuery.get("ref");
    const { isWebView } = mainStore.getState().uiStates;
    try {
      localStorage.setItem("google_ref", ref);
    } catch (error) {}
    axios
      .get(
        `/users/google_oauth/?is_mobile=${isWebView}${
          app_id ? "&app_id=" + app_id : ""
        }&conv_id=${sessionId}`
      )
      .then((res) => {
        let state_id = res.data.state_id;

        try {
          localStorage.setItem("google_state_id", state_id);
          // localStorage.setItem("conversation_id", res.data?.gpt_store_conv_id);
        } catch (e) {}
        if (isWebView) {
          window?.ReactNativeWebView?.postMessage(`oauth ||${res?.data?.url}`);
          // create a wrapper function for removeeventlistener
          const wrapSignup = (e) =>
            signupRedirect(e, "/oauth/google_login/?is_mobile=true")
              .then((d) => resolve(d))
              .catch((e) => reject(e))
              .finally((e) => {
                getListenerParent().removeEventListener("message", wrapSignup);
              });
          getListenerParent().addEventListener("message", wrapSignup);
        } else {
          // console.log(res.data.url);
          // const url = res.data.url.split("?");
          // const params = queryString.parse(url[1]);
          // params.redirect_uri = "http://localhost:3000/oauth/google_login"
          // const finalUrl = url[0] + "?" + queryString.stringify(params);
          // console.log(finalUrl);
          // console.log("redirect URL - ", res.data.url);
          // const url = new URL(res.data.url);
          // url.searchParams.set("redirect_uri", "https://localhost:3000/oauth/google_login");
          // console.log(url);
          if (app_id) {
            openSignInWindow(res.data.url, "Google Login", (event) => {
              console.log("event - ", event);
              const data = event.data;
              data.params && finishGoogleLogin({ ...data, appId: app_id }, navigate);
            });
          } else {
            console.log("in Else");
            window.location.href = res.data.url;
          }
        }
      })
      .catch((err) =>
        mainStore.dispatch({
          type: "uiStates/showAlert",
          alertType: "error",
          payload: err.message
        })
      );
  });
};

export const loginApple = (app_id, sessionId, navigate) => {
  return new Promise((resolve, reject) => {
    const pathQuery = new URLSearchParams(window.location.search);
    const ref = pathQuery.get("ref");
    const { isWebView } = mainStore.getState().uiStates;
    axios
      .get(
        `/users/ios_login_oauth/?is_mobile=${isWebView}${
          app_id ? "&app_id=" + app_id : ""
        }&conv_id=${sessionId}`
      )
      .then((res) => {
        if (isWebView) {
          window?.ReactNativeWebView?.postMessage(`oauth ||${res?.data?.url}`);
          // /oauth/apple_login/?is_mobile=true
          const wrapSignup = (e) => {
            signupRedirect(e, "/oauth/apple_login/?is_mobile=true")
              .then((d) => resolve(d))
              .catch((e) => reject(e))
              .finally((e) => getListenerParent().removeEventListener("message", wrapSignup));
          };
          getListenerParent().addEventListener("message", wrapSignup);
        } else {
          if (app_id) {
            openSignInWindow(res.data.url, "Apple Login", (event) => {
              const data = event.data;
              data.params && finishAppleLogin({ ...data, appId: app_id }, navigate);
            });
          } else {
            window.location.href = res.data.url;
          }
        }
      })
      .catch((err) =>
        mainStore.dispatch({
          type: "uiStates/showAlert",
          alertType: "error",
          payload: err.message
        })
      );
  });
};

export const googleOneTapInit = (cb) => {
  const googleScript = document.createElement("script");
  googleScript.src = "https://accounts.google.com/gsi/client";
  googleScript.async = true;
  googleScript.onload = () => {
    if (window?.google) {
      window?.google.accounts.id.initialize({
        client_id: "295142708-98jlsb1vh0ah20ndt53jbktnklah9rbp.apps.googleusercontent.com",
        callback: (res) => cb(res?.credential)
      });
      // For google login button
      window?.google.accounts.id.renderButton(
        document.getElementById("googleLogin"), // change the "googleLogin" id to your button id
        {
          theme: "filled_blue", // outline, filled_black - check whatever looks good
          shape: "pill", // rectangular
          size: "large"
        } // customization attributes
      );
      // For google one-tap login
      window?.google.accounts.id.prompt((notification) => {
        // also display the onetap dialog
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log("not displayed due to  - ", notification.getNotDisplayedReason());
          // continue with another identity provider.
        }
      });
    }
  };
  window?.document.head.appendChild(googleScript);
};
export const isFacebookApp = () => {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  const isFb = ua && (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1);
  const isIg = ua && ua.indexOf("Instagram") > -1;
  return isFb || isIg;
};
