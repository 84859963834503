import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sharePlatforms: {
    ig: {
      // Multiple can be true at a time.
      publish: true,
      disabled: true
    },
    igs: {
      // Multiple can be true at a time.
      publish: false,
      disabled: true
    },
    fb: {
      publish: false,
      disabled: true
    },
    fbr: {
      publish: false,
      disabled: true
    },
    li: {
      publish: false,
      disabled: true
    },
    pi: {
      publish: false,
      disabled: true
    },
    gmb: {
      publish: false,
      disabled: true
    },
    tt: {
      publish: false,
      disabled: true
    },
    // fbg: {
    //   publish: false,
    //   disabled: true
    // },
    twt: {
      publish: false,
      disabled: true
    },
    yt: {
      publish: false,
      disabled: true
    }
  },
  displayPlatform: "ig",
  shareData: {},
  fetchingData: true,
  sharePlatformCreativeJson: {},
  brandName: "",
  chatHistory: [],
  postIdea: "",
  postStatus: "",
  scheduleDateTime: "",
  clientEmail: "",
  apiViewType: "",
  sharingAction: "",
  sharingLink: "",
  ownerUserEmail: "",
  sharingDate: "",
  isPostEdited: false,
  event_id: ""
};

const updatePlatformData = (state, action) => {
  if (state.sharePlatforms[action.platform])
    state.sharePlatforms[action.platform][action.property] = action.payload;
};
const setOwnerUserEmail = (state, action) => {
  state.ownerUserEmail = action.payload;
};
const setIsPostEdited = (state, action) => {
  state.isPostEdited = action.payload;
};
const setSharingDate = (state, action) => {
  state.sharingDate = action.payload;
};
const setSharingLink = (state, action) => {
  state.sharingLink = action.payload;
};
const setSharingAction = (state, action) => {
  state.sharingAction = action.payload;
};
const setApiViewType = (state, action) => {
  state.apiViewType = action.payload;
};
const setClientEmail = (state, action) => {
  state.clientEmail = action.payload;
};
const setPostIdea = (state, action) => {
  state.postIdea = action.payload;
};
const setPostStatus = (state, action) => {
  state.postStatus = action.payload;
};
const setScheduleDateTime = (state, action) => {
  state.scheduleDateTime = action.payload;
};
const setChatHistory = (state, action) => {
  state.chatHistory = action.payload;
};
const setBrandName = (state, action) => {
  state.brandName = action.payload;
};
const setDisplayPlatform = (state, action) => {
  state.displayPlatform = action.payload;
};
const setSameTimeChecked = (state, action) => {
  state.sameTimeChecked = action.payload;
};
const setShareData = (state, action) => {
  state.shareData[action.platName] = action.payload;
};
const setSelectedEditButton = (state, action) => {
  state.selectedEditButton = action.payload;
};
const setFetchingData = (state, action) => {
  state.fetchingData = action.payload;
};
const updatePlatformDateTime = (state, action) => {
  state.platformDateTime[action.platName] = action.payload;
};
const updateShareData = (state, action) => {
  if (state.shareData[action.platName])
    state.shareData[action.platName][action.property] = action.payload;
};
const setSelectedIds = (state, action) => {
  state.selectedIds[action.platName] = action.payload;
};
const setGmbCta = (state, action) => {
  state.gmbCTA = action.payload;
};
const updateCreativeJson = (state, action) => {
  state.sharePlatformCreativeJson = {
    ...state.sharePlatformCreativeJson,
    ...action.payload
  };
};
const setPlatformDateTime = (state, action) => {
  state.platformDateTime = action.payload;
};
const addChatHistory = (state, action) => {
  state.chatHistory = [{ ...action.payload }, ...state.chatHistory];
};
const setEventId = (state, action) => {
  state.event_id = action.payload;
};

const resetSharingStates = () => initialState;

const sharingStates = createSlice({
  name: "sharingStates",
  initialState,
  reducers: {
    updatePlatformData,
    setSharingAction,
    setPostIdea,
    setApiViewType,
    addChatHistory,
    setSharingLink,
    setClientEmail,
    setScheduleDateTime,
    setPostStatus,
    setDisplayPlatform,
    setSameTimeChecked,
    setIsPostEdited,
    setShareData,
    setBrandName,
    setChatHistory,
    setSelectedEditButton,
    setFetchingData,
    updateShareData,
    updatePlatformDateTime,
    setSelectedIds,
    resetSharingStates,
    setOwnerUserEmail,
    setSharingDate,
    setGmbCta,
    updateCreativeJson,
    setPlatformDateTime,
    setEventId
  }
});

export default sharingStates.reducer;
