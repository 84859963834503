import { Box, Grid, Typography } from "./designSystem";
import * as Sentry from "@sentry/react";
import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  globalStates: state.globalReducer
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  globalStates = this.props.globalStates;

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            textAlign: "center",
            marginTop: "2rem",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            width: { xs: "100%", md: "auto" }
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                src={process.env.PUBLIC_URL + "/error.png"}
                width={"200px"}
                style={{ marginRight: "10px" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Typography variant={"h4"}>Oops! Something went wrong 🙈</Typography>
                {this.props.errorMsg && (
                  <>
                    <div>{this.props.errorMsg}</div>
                    <br></br>
                  </>
                )}
                <div>
                  Please try{" "}
                  <a
                    href='#'
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <b>refreshing the page</b>
                  </a>
                  and try again.
                  <br></br>
                  If you are using a browser translator, please turn off the translator and then
                  refresh this page. We encourage you to change the language from the app itself.
                  <br></br>
                  If the problem persists then reach out to us through Chat. Our apologies for this!
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return this.props.children;
  }
}
// export default ErrorBoundary;
export default connect(mapStateToProps)(ErrorBoundary);
