import * as Sentry from "@sentry/react";
import axios from "axios";
import { v4 as uuid4 } from "uuid";
import mainStore from "../MainStore/store";
import { LOGIN_PLATFORM_SEMRUSH } from "../utils/constants";
import { localStorageSafe } from "../utils/localStorageUtils";

export const API_ADDRESS = process.env.REACT_APP_API_URL; // main backend api address

export const authAxios = axios.create({
  baseURL: API_ADDRESS
});

const showPlanExpiredModal = (error) => {
  // error - axios Error
  mainStore.dispatch({
    type: "userData/setPlanExpired",
    payload: true
  });
  if (error?.response?.data?.paused) {
    mainStore.dispatch({
      type: "userData/setPlanDetails",
      payload: true,
      key: "planPaused"
    });
  }
};
const reqInterceptor = (config) => {
  config.headers["X-Amzn-Trace-Id"] = uuid4();
  config.headers.Authorization = `Bearer ${mainStore.getState().globalReducer.jwt}`;
  return config;
};
const resInterceptorSuccess = (response) => {
  return response;
};

const resInterceptorError = async (error) => {
  // Do something with response error
  const originalRequest = error?.config;
  const { loginPlatform } = mainStore.getState().userData;

  if (error?.response && error?.response?.status === 401 && !originalRequest?._retry) {
    originalRequest._retry = true;

    if (loginPlatform === LOGIN_PLATFORM_SEMRUSH) {
      // in case of 401 unauthorized and if this is not a retry request
      try {
        // get new token
        const token = await getSMRefreshToken();
        // change common authorization header in store
        mainStore.dispatch({ type: "setJWT", payload: token });
        // change immediate header
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        // write in local storage in case use refreshes
        localStorageSafe("setItem", "jwt", token);
        return authAxios(originalRequest);
      } catch (error) {
        if (error.name === "SMError") {
          return Promise.reject(
            new Error("Authentication failed. Please login again or refresh the page")
          );
        }
        mainStore.dispatch({ type: "userData/setSessionExpired", payload: true });
        return Promise.reject(error);
      }
    } else {
      // get new token
      try {
        // change immediate header
        const token = await getPredisRefreshToken();
        // write in local storage in case use refreshes
        mainStore.dispatch({ type: "setJWT", payload: token });
        localStorageSafe("setItem", "jwt", token);
        // change common authorization header
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (error) {
        mainStore.dispatch({ type: "userData/setSessionExpired", payload: true });
        return Promise.reject(error);
      }
    }
  } else if (error?.response?.status === 426 && !originalRequest?._retry) {
    // Show plan expired modal
    showPlanExpiredModal(error);
  } else {
    Sentry.captureException(error, {
      tags: { "X-Amzn-Trace-Id": error.config?.headers?.["X-Amzn-Trace-Id"] }
    });
    try {
      const error_msg = error?.response?.data?.message || `${error.message} - ${error.config?.url}`;
      mainStore.dispatch({
        type: "uiStates/showAlert",
        alertType: "error",
        payload: error_msg
      });
    } catch (error) {}

    return Promise.reject(error);
  }
};

export const getPredisRefreshToken = () => {
  // for refreshing predis-backend token
  return new Promise((resolve, reject) => {
    const refreshToken = mainStore.getState().globalReducer.refreshToken;
    if (refreshToken) {
      const sendData = new URLSearchParams({
        refresh_token: refreshToken
      });
      axios
        .get(`${API_ADDRESS}/users/refresh_user_jwt_token/?${sendData}`)
        .then(({ data }) => {
          if (data?.new_jwt_token) resolve(data?.new_jwt_token);
          else reject(new Error("Unauthorised"));
        })
        .catch((err) => reject(err));
    } else {
      reject(new Error("No refresh token"));
    }
  });
};

export const getSMRefreshToken = () => {
  // for refreshing semrush token
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    // window.onSmSdkLoad = () => {
    if (window.SM) {
      // All methods are available now.
      try {
        const newToken = await window.SM.client("getAccessToken");
        resolve(newToken);
      } catch (error) {
        // if SM is not init
        console.log("sm.client error - ", error);
        // window.SM.init().then(async () => {
        //   const newToken = await window.SM.client("getAccessToken");
        //   resolve(newToken);
        // });
        reject(error);
      }

      // });
    } else {
      // console.error("SM variable not available");
      const er = new Error("SM variable not available");
      er.name = "SMError";
      reject(er);
      // const semrushScript = document.createElement("script");
      // semrushScript.src = "https://static.semrush.com/app-center/sdk.js";
      // semrushScript.async = true;
      // semrushScript.onload = () => {
      //   console.log("semrush script loaded");
      //   window.SM.init()
      //     .then(async () => {
      //       const newToken = await window.SM.client("getAccessToken");
      //       resolve(newToken);
      //       // SM.setAutoHeightUpdate({ enable: true })
      //     })
      //     .catch(() => console.log("error in init"));
      // };
      // // semrushScript.setAttribute('src', 'https://static.semrush.com/app-center/sdk.js');
      // document.head.appendChild(semrushScript);
    }
    // "SM" variable is available now.
    reject("Unknown error");
    // };
  });
};

export const setupInterceptors = () => {
  // set interceptors for authAxios
  // request interceptor
  authAxios.interceptors.request.use(reqInterceptor);
  authAxios.interceptors.response.use(resInterceptorSuccess, resInterceptorError);
};
