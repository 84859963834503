// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import englishTrans from "../public/locales/en/translation.json";
// import spanishTrans from "./locales/Spanish/es.json";
// import dutchTrans from "./locales/Dutch/nl.json";
// import frenchTrans from "./locales/French/fr.json";
// import germanTrans from "./locales/German/de.json";
// import italianTrans from "./locales/Italian/it.json";
// import portugueseTrans from "./locales/Portuguese_BR/pt.json";
// import resourcesToBackend from "i18next-resources-to-backend";
import backend from "i18next-http-backend";
import { localStorageSafe } from "./utils/localStorageUtils";

// const resources = {
//   es: {
//     translation: spanishTrans
//   },
//   en: {
//     translation: englishTrans
//   },
//   nl: {
//     translation: dutchTrans
//   },
//   de: {
//     translation: germanTrans
//   },
//   fr: {
//     translation: frenchTrans
//   },
//   it: {
//     translation: italianTrans
//   },
//   pt: {
//     translation: portugueseTrans
//   }
// };
const detectedLanguage = navigator.language;
const configLang = localStorageSafe("getItem", "app_language");
const defaultLanguage = configLang || detectedLanguage || fallBackLanguage;
const fallBackLanguage = "en";
localStorageSafe("setItem", "app_language", defaultLanguage);
i18n
  .use(backend)
  .use(initReactI18next)
  // .use(
  //   resourcesToBackend((language, namespace) => {
  //     console.log("i18 - ", language, namespace);
  //     import(`./locales/${language}/${namespace}.json`);
  //   })
  // )
  .init({
    // resources,
    lng: defaultLanguage,
    fallbackLng: fallBackLanguage,
    debug: false,
    localeDetection: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translation.json?v=${process.env.REACT_APP_BUILD_VERSION}`
    }
  });
i18n.on("failedLoading", (lng, ns, msg) => {
  i18n.changeLanguage(fallBackLanguage);
  localStorageSafe("setItem", "app_language", fallBackLanguage);
});

export default i18n;
