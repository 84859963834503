import { styled } from "@mui/material/styles";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import * as React from "react";
// import WarningIcon from "@mui/icons-material/Warning";
// import newTheme from "./NewTheme";
interface CustomToggleButtonProps extends ToggleButtonProps {
  // Add any additional props if needed
}

const PredisToggleButton = styled(ToggleButton)<CustomToggleButtonProps>(({ theme }) => {
  return {
    "&.MuiToggleButton-root": {
      //   borderRadius: "16px",
      //   padding: "0.5rem 1.5rem",

      //   margin: "0.5rem",
      "&.Mui-selected": {
        boxShadow: "0px 2px 4px 0px #171B2826",
        // backgroundColor: "#FFD700",
        // color: "black",
        "&:hover": {
          // backgroundColor: "#FFD700"
        }
      },
      "&:hover": {
        // backgroundColor: "#FFD700"
      }
    }
  };
});

const StyledToggleButton: React.FC<CustomToggleButtonProps> = (props) => {
  return <PredisToggleButton disableRipple {...props} />;
};
export default StyledToggleButton;
