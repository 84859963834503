import mainStore from "../../MainStore/store";
import { localStorageSafe } from "../../utils/localStorageUtils";

export const logoutFn = (navigate, path) => {
  // var login_platform = localStorage.getItem("login_platform");
  // const { loginPlatform } = mainStore.getState().userData;
  // const appId = localStorageSafe("getItem", "appId");

  // remove_account_details();
  const prevLang = localStorageSafe("getItem", "app_language");
  localStorageSafe("clear");
  localStorageSafe("setItem", "app_language", prevLang);

  // localStorageSafe("setItem", "appId", appId);
  mainStore.dispatch({ type: "logout" });
  if (navigate && path) navigate(path);

  // window.location = "./";
  return;
};

// export const logout_facebook = async () => {
//   try {
//     window.FB.getLoginStatus(function (response) {
//       if (response["status"] == "connected") {
//         window.FB.logout(function (response) {
//           return new Promise((resolve) => resolve(true));
//         });
//       } else {
//         return new Promise((resolve) => resolve(true));
//       }
//     });
//   } catch (error) {
//     return new Promise((resolve) => resolve(true));
//   }
// };
