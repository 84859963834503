import { createSlice } from "@reduxjs/toolkit";
let initShopifySignup = false;
let initNeedShopifyDownload = false;
let initshopifyWsLimitAvail = true;
let initShopifyUser = false;

try {
  initShopifySignup = JSON.parse(localStorage.getItem("shopify_signup"));
  initNeedShopifyDownload = JSON.parse(localStorage.getItem("need_download_shopify_products"));
  initshopifyWsLimitAvail = JSON.parse(localStorage.getItem("spf_ws_limit_available"));
  initShopifyUser = JSON.parse(localStorage.getItem("shopify_user"));
} catch (error) {}
const initialState = {
  ecom_platform: [],
  ecom_linked: false,
  shopifySignup: initShopifySignup,
  needShopifydownload: initNeedShopifyDownload,
  shopifyWsLimitAvail: initshopifyWsLimitAvail,
  shopifyUser: initShopifyUser,
  nOnce: "0",
  showShopifyNudge: false
};

const setShowShopifyNudge = (state, action) => {
  state.showShopifyNudge = action.payload;
};
const setShopifySignup = (state, action) => {
  state.shopifySignup = action.payload;
};
const setNeedShopifyDownload = (state, action) => {
  state.needShopifydownload = action.payload;
};
const setShopifyWsLimitAvail = (state, action) => {
  state.shopifyWsLimitAvail = action.payload;
};
const setNOnce = (state, action) => {
  state.nOnce = action.payload;
};
const setShopifyUser = (state, action) => {
  state.shopifyUser = action.payload;
};
const setEcomPlatform = (state, action) => {
  state.ecom_platform = action.payload;
};
const setEcomLinked = (state, action) => {
  state.ecom_linked = action.payload;
};

const shopifyStateSlice = createSlice({
  name: "shopifyStates",
  initialState,
  reducers: {
    setShopifySignup,
    setNeedShopifyDownload,
    setShopifyWsLimitAvail,
    setNOnce,
    setShopifyUser,
    setShowShopifyNudge,
    setEcomPlatform,
    setEcomLinked
  }
});

export default shopifyStateSlice.reducer;
