// import { styled } from "@mui/material/styles";
// import Autocomplete, {AutocompleteProps} from "@mui/material/Autocomplete";

// const PredisAutocomplete = styled(Autocomplete)(({ theme }) => ({
//   //   padding: "2.25rem",
//   "& .MuiAutocomplete-inputRoot": {
//     // height: "3rem",
//     borderRadius: "10px"
//   }
//   //   "& .MuiTextField-root": {
//   //     padding: "10px"
//   //   }
// }));

// export default function StyledAutocomplete({ ...props }) {
//   return <PredisAutocomplete {...props} />;
// }

// StyledAutocomplete.tsx
import React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

// Extend the AutocompleteProps interface from MUI
interface CustomAutocompleteProps<T> extends AutocompleteProps<T, any, any, any> {
  // Add any additional props if needed
}

// Create a styled Autocomplete
const PredisAutocomplete = styled(Autocomplete)<CustomAutocompleteProps<any>>(({ theme }) => ({
  "& .MuiAutocomplete-inputRoot": {
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white
  }
}));

// Create a React functional component that uses your custom props
const StyledAutocomplete: React.FC<CustomAutocompleteProps<any>> = (props) => {
  return <PredisAutocomplete {...props} />;
};

export default StyledAutocomplete;
