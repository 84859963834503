import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  stepsData: {
    step_1: {
      credits: 8,
      completed: true
    },
    step_2: {
      credits: 5,
      completed: false
    },
    step_3: {
      credits: 5,
      completed: false
    },
    step_4: {
      credits: 5,
      completed: false
    },
    step_5: {
      credits: 10,
      completed: false
    },
    step_6: {
      credits: 0,
      completed: false
    },
    step_7: {
      credits: 0,
      completed: false
    }
  },
  allSteps: ["step_1", "step_2", "step_3", "step_4", "step_5", "step_6"],
  stepsExtras: {
    extraBtnTexts: {
      step_1: {
        btnText: ""
      },
      step_2: {
        btnText: "Try Now!"
      },
      step_3: {
        btnText: "Watch Now!"
      },
      step_4: {
        btnText: "Try Now!"
      },
      step_5: {
        btnText: "Try Now!"
      },
      step_6: {
        btnText: ""
      }
    },
    extraBtnFunctions: {
      // TODO Edit them accordingly later
      step_1: {
        dispatcher: "",
        payload: true
      },
      step_2: {
        dispatcher: "stepComponentSlice/updateBoolVals",
        payload: {
          property: "navigateCreateLib",
          value: true
        }
      },
      step_3: {
        dispatcher: "stepComponentSlice/updateBoolVals",
        payload: {
          property: "showVideoModal",
          value: true
        }
      },
      step_4: {
        dispatcher: "stepComponentSlice/updateBoolVals",
        payload: {
          property: "navigateLib",
          value: true
        }
      },
      step_5: {
        dispatcher: "stepComponentSlice/updateBoolVals",
        payload: {
          property: "navigateLib",
          value: true
        }
      },
      step_6: {
        dispatcher: "",
        payload: ""
      },
      step_7: {
        dispatcher: "",
        payload: ""
      }
    },
    stepsLabel: {
      step_1: {
        label: "Complete Onboarding and make 3 posts"
      },
      step_2: {
        label: "Create a New post"
      },
      step_3: {
        label: "Watch Demo Video"
      },
      step_4: {
        label: "Edit Any Post and Switch templates"
      },
      step_5: {
        label: "Publish a post to any social media handle"
      },
      step_6: {
        label: "Complete Steps to Unlock Offer 🎁"
      },
      step_7: {
        label: "Get 30% off on Yearly Plans. use code PREDIS30Y"
      }
    }
  },
  boolVals: {
    showVideoModal: false,
    navigateCreateLib: false,
    navigateLib: false
  },
  totalCredits: 8,
  stepsProgress: 5,
  stepsLoading: true
};

const setStepsData = (state, action) => {
  state.stepsData = action.payload;
};
const appendAllSteps = (state, action) => {
  state.allSteps = [...state.allSteps, action.payload];
};
const updateExtraButtonsText = (state, action) => {
  state.stepsExtras.extraBtnTexts[action.stepId].btnText = action.payload;
};
const updateStepsData = (state, action) => {
  state.stepsData[action.step][action.property] = action.payload;
};
const setTotalCredits = (state, action) => {
  state.totalCredits = action.payload;
};
const updateBoolVals = (state, action) => {
  state.boolVals[action.payload.property] = action.payload.value;
};
const setStepsProgress = (state, action) => {
  state.stepsProgress = action.payload;
};
const updateStep6Label = (state, action) => {
  state.stepsExtras.stepsLabel.step_6.label = action.payload;
};
const setStepsLoading = (state, action) => {
  state.stepsLoading = action.payload;
};
const resetStates = () => initialState;

const stepComponentSlice = createSlice({
  name: "stepComponentSlice",
  initialState,
  reducers: {
    updateStepsData,
    setTotalCredits,
    updateBoolVals,
    setStepsProgress,
    setStepsData,
    updateStep6Label,
    appendAllSteps,
    updateExtraButtonsText,
    setStepsLoading,
    resetStates
  }
});

export default stepComponentSlice.reducer;
