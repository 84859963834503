import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import * as React from "react";
// import { Padding } from "@mui/icons-material";
// import newTheme from "./NewTheme";

interface CustomILProps extends InputLabelProps {
  // Add any additional props if needed
}

const PredisInputLabel = styled(InputLabel)<CustomILProps>(({ theme }) => ({
  "&.MuiInputLabel-shrink": {
    backgroundColor: theme.palette.common.white
  }
}));

const StyledInputLabel: React.FC<CustomILProps> = (props) => {
  // return onlyIcon ? <PredisButton {...props} /> : <PredisButton {...props}>{text}</PredisButton>;
  return <PredisInputLabel {...props} />;
};

export default StyledInputLabel;
export * from "@mui/material/InputLabel";
