import { SvgIcon } from "../../../designSystem";

const VietnamFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='900'
        height='600'
        viewBox='0 0 30 20'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
      >
        <rect width='30' height='20' fill='#da251d' />
        <polygon points='15,4 11.47,14.85 20.71,8.15 9.29,8.15 18.53,14.85' fill='#ff0' />
      </svg>
    </SvgIcon>
  );
};

export default VietnamFlagIcon;
