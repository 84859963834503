import { createSlice } from "@reduxjs/toolkit";

const initFilters = {
  ecomSelected: false,
  templateType: "video",
  templateOrientation: "all",
  templateEmotion: "all",
  videoLength: "long",
  selectedTags: [],
  textSearch: "",
  isVoiceover: false
};

const initialState = {
  ui: {
    styleValue: ["Professional", "Playful", "Vibrant", "Minimal"],
    toneVal: "Casual",
    activeStep: 0,
    openMobileDrawer: false,
    stepTwoActiveTab: "",
    blogLinkSubmitting: false,
    allSteps: {
      0: {
        title: "contentLibrary.newPost.allSteps.yourInput"
      },
      1: { title: "contentLibrary.newPost.allSteps.chooseTemp" },
      2: { title: "contentLibrary.newPost.allSteps.finishing" }
    },
    activeTab: "idea",
    tabs: {
      0: {
        idea: {
          label: "contentLibrary.newPost.topbarlabels.id0.label1"
        },
        multi: {
          label: "contentLibrary.newPost.topbarlabels.id0.label2"
        },
        business: {
          label: "contentLibrary.newPost.topbarlabels.id0.label3"
        },
        blog: {
          label: "contentLibrary.newPost.topbarlabels.id0.label4"
        },
        ecom: {
          label: "commonTexts.eCommerce"
        },
        quotes: {
          label: "commonTexts.quotes"
        },
        meme: {
          label: "contentLibrary.newPost.topbarlabels.id0.label5"
        },
        special_day: {
          label: "contentLibrary.newPost.topbarlabels.id0.label6"
        },
        manual: {
          label: "commonTexts.manualPost"
        }
      },
      1: {
        video: {
          label: "contentLibrary.newPost.topbarlabels.id1.label4"
        },
        ads: {
          label: "contentLibrary.newPost.topbarlabels.id1.label3"
        },
        image: {
          label: "commonTexts.singleImage"
        },
        stories: {
          label: "contentLibrary.newPost.topbarlabels.id1.label5"
        },
        special_day: {
          label: "contentLibrary.newPost.topbarlabels.id0.label6"
        },
        meme: {
          label: "contentLibrary.newPost.topbarlabels.id0.label5"
        },
        quotes: {
          label: "commonTexts.quotes"
        },
        carousel: {
          label: "commonTexts.carousel"
        }
      },
      2: {
        asset: {
          label: "contentLibrary.newPost.topbarlabels.id1.label1"
        },
        advance: {
          label: "contentLibrary.newPost.topbarlabels.id1.label2"
        }
      }
    },
    filters: {
      ecomSelected: false,
      templateType: "video",
      templateOrientation: "all",
      templateEmotion: "all",
      videoLength: "long",
      selectedTags: [],
      textSearch: "",
      isVoiceover: false
    },
    availableFilters: {
      allTags: []
    }
  },
  postTypeNError: false,
  postTypeVal: "",
  openFrom: "",
  navigateTo: "",
  ValidationErrors: {
    ideaErr: {
      error: false,
      msg: ""
    },
    businessErr: {
      error: false,
      msg: ""
    },
    ecomErr: {
      error: false,
      msg: ""
    },
    quoteErr: {
      error: false,
      msg: ""
    },
    specialDayErr: {
      error: false,
      msg: ""
    },
    manualPostErr: {
      error: false,
      msg: ""
    },
    memePostErr: {
      error: false,
      msg: ""
    },
    scriptPostErr: {
      error: false,
      msg: ""
    },
    blogPostErr: {
      error: false,
      msg: ""
    }
  },
  templates: {
    byId: {},
    allIds: [],
    selectedId: [],
    templatesLoading: false,
    templatePageNo: 1,
    templateTotalPages: 1
  },
  languages: {
    inputLang: { label: "🇺🇸 English", value: "english" },
    outputLang: { label: "🇺🇸 English", value: "english" }
  },
  businessSendVals: {
    description: "",
    products: "",
    audience: "",
    benefits: ""
  },
  memeIdeaVal: "",
  memeTemplateId: "",
  memeType: "",
  brandColors: [],
  selectedAssetIds: [],
  selectedOwnSource: "company",
  assetsById: {},
  selectedBlogImages: [],
  selectedSearchImgUrls: [],
  selectedVideoAsset: false,
  selectedAssetType: "ai",
  isBlogPreviewActive: false,
  allBlogImages: [],
  blogVariants: {
    activeVariantId: "",
    allIds: [],
    byId: {}
  }
};

const setPostTypeVal = (state, action) => {
  state.postTypeVal = action.payload;
};
const setPostTypeNError = (state, action) => {
  state.postTypeNError = action.payload;
};
const setOpenFrom = (state, action) => {
  state.openFrom = action.payload;
};
const setOpenMobileDrawer = (state, action) => {
  state.ui.openMobileDrawer = action.payload;
};
const setAllFilterTags = (state, action) => {
  state.ui.availableFilters.allTags = action.payload;
};
const setSelectedFilterTags = (state, action) => {
  // console.log("set selected filters - ", current(state).ui.filters.tags.selectedTags)

  state.ui.filters.selectedTags = action.payload;
  // console.log("new", current(state).ui.filters.tags.selectedTags)
};
const setAssetsById = (state, action) => {
  // LoopArr should have unique ids
  const loopArr = action.payload;
  loopArr.forEach((elem) => {
    state.assetsById[elem.asset_id] = elem;
  });
};
const addAllTemplateIds = (state, action) => {
  // LoopArr should have unique ids
  const loopArr = action.payload;
  loopArr.forEach((elem) => {
    state.templates.allIds.push(elem.id);
    state.templates.byId[elem.id] = elem;
  });
};
const setNavigateTo = (state, action) => {
  state.navigateTo = action.payload;
};
const setBlogLinkSubmitting = (state, action) => {
  state.ui.blogLinkSubmitting = action.payload;
};
const setBusinessSendVals = (state, action) => {
  state.businessSendVals = action.payload;
};
const setStepTwoActiveTab = (state, action) => {
  state.ui.stepTwoActiveTab = action.payload;
};
const setSelectedSearchImgUrls = (state, action) => {
  state.selectedSearchImgUrls = action.payload;
};
const setBrandColors = (state, action) => {
  state.brandColors = action.payload;
};
const setInputLanguage = (state, action) => {
  state.languages.inputLang = action.payload;
};
const setOutputLanguage = (state, action) => {
  state.languages.outputLang = action.payload;
};

const resetTemplateFilters = (state) => {
  state.ui.filters = initFilters;
};

const setTemplateSelectedId = (state, action) => {
  // LoopArr should have unique ids
  state.templates.selectedId = action.payload;
};
const modifyTemplateData = (state, action) => {
  // LoopArr should have unique ids
  state.templates.byId[action?.id][action?.key] = action.payload;
};
const setAllTemplateIds = (state, action) => {
  // LoopArr should have unique ids
  const loopArr = action.payload;
  state.templates.allIds = [];
  loopArr.forEach((elem) => {
    state.templates.allIds.push(elem.id);
    state.templates.byId[elem.id] = elem;
  });
};

const setTemplateTotalPages = (state, action) => {
  state.templates.templateTotalPages = action.payload;
};
const setTemplatePageNo = (state, action) => {
  state.templates.templatePageNo = action.payload;
};
const setTemplatesLoading = (state, action) => {
  state.templates.templatesLoading = action.payload;
};
const setFilters = (state, action) => {
  state.ui.filters[action.filterType] = action.payload;
};

const resetAndSetFilters = (state, action) => {
  const copy = { ...initFilters };
  // copy[action.filterType] = action.payload;
  const newValues = { ...initFilters, ...action.payload };
  // console.log("copy after change", newValues)
  state.ui.filters = newValues;
};
const setActiveTab = (state, action) => {
  state.ui.activeTab = action.payload;
};
const setActiveStep = (state, action) => {
  state.ui.activeStep = action.payload;
};

const setAllBlogImages = (state, action) => {
  state.allBlogImages = action.payload;
};
const setSelectedBlogImages = (state, action) => {
  state.selectedBlogImages = action.payload;
};
const setActiveBlogVariantId = (state, action) => {
  state.blogVariants.activeVariantId = action.payload;
};
const setSlidesArr = (state, action) => {
  let activeId = action.activeVariantId;
  state.blogVariants.byId[activeId].arr = action.payload;
};
const setVariantIds = (state, action) => {
  // LoopArr should have unique ids
  const loopArr = action.payload;
  state.blogVariants.allIds = [];
  loopArr.forEach((elem) => {
    state.blogVariants.allIds.push(elem.id);
    state.blogVariants.byId[elem.id] = elem;
  });
};
const setIsBlogPreviewActive = (state, action) => {
  state.isBlogPreviewActive = action.payload;
};
const setMemeType = (state, action) => {
  state.memeType = action.payload;
};
const setMemeIdeaVal = (state, action) => {
  state.memeIdeaVal = action.payload;
};
const setMemeTemplateId = (state, action) => {
  state.memeTemplateId = action.payload;
};
const setStyleValue = (state, action) => {
  state.ui.styleValue = action.payload;
};

const setToneVal = (state, action) => {
  state.ui.toneVal = action.payload;
};
const setErrors = (state, action) => {
  state.ValidationErrors[action.key].error = action.payload;
  state.ValidationErrors[action.key].msg = action.msg;
};
const resetErrors = (state) => {
  state.ValidationErrors = initialState.ValidationErrors;
};
const setSelectedAssetIds = (state, action) => {
  state.selectedAssetIds = action.payload;
};
const setSelectedOwnSource = (state, action) => {
  state.selectedOwnSource = action.payload;
};
const setSelectedVideoAsset = (state, action) => {
  state.selectedVideoAsset = action.payload;
};
const setSelectedAssetType = (state, action) => {
  state.selectedAssetType = action.payload;
};

const resetNewPostStates = () => initialState;

const newPostStatesSlice = createSlice({
  name: "newPostStates",
  initialState,
  reducers: {
    setPostTypeNError,
    setOpenFrom,
    setPostTypeVal,
    setOpenMobileDrawer,
    resetAndSetFilters,
    setAllFilterTags,
    setSelectedFilterTags,
    setNavigateTo,
    setBlogLinkSubmitting,
    setAssetsById,
    setBusinessSendVals,
    setStepTwoActiveTab,
    setSelectedSearchImgUrls,
    setBrandColors,
    resetNewPostStates,
    resetTemplateFilters,
    setTemplateSelectedId,
    setAllTemplateIds,
    setFilters,
    setMemeType,
    setAllBlogImages,
    addAllTemplateIds,
    setSelectedBlogImages,
    setTemplatesLoading,
    setMemeTemplateId,
    setTemplateTotalPages,
    setTemplatePageNo,
    setMemeIdeaVal,
    setStyleValue,
    setToneVal,
    setErrors,
    modifyTemplateData,
    setActiveStep,
    setActiveTab,
    resetErrors,
    setVariantIds,
    setSlidesArr,
    setActiveBlogVariantId,
    setSelectedAssetIds,
    setSelectedOwnSource,
    setSelectedVideoAsset,
    setSelectedAssetType,
    setIsBlogPreviewActive,
    setInputLanguage,
    setOutputLanguage
  }
});

export default newPostStatesSlice.reducer;
