import { styled } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import * as React from "react";

const PredisTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-scroller": {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  "& .MuiTabs-indicator": {
    height: "3px",
    borderRadius: "4px 4px 0 0"
  }
}));

const StyledTabs: React.FC<TabsProps> = (props) => {
  return <PredisTabs {...props} />;
};

export default StyledTabs;
