import Popover, { PopoverProps } from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import React from "react";

// Extend the PopoverProps interface from MUI
interface CustomPopoverProps extends PopoverProps {
  // Add any additional props if needed
}

// Create a styled dialog
const PredisPopover = styled(Popover)<CustomPopoverProps>(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: "10px",
    padding: "0.25rem"
  }
}));

const StyledPopover: React.FC<CustomPopoverProps> = (props) => {
  return <PredisPopover {...props} />;
};

export default StyledPopover;
