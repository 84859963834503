import { createSlice } from "@reduxjs/toolkit";
const isWebView = new URLSearchParams(window.location.search).get("v") === "webview";
const initialState = {
  billingType: "month",
  workspaceLimits: {
    total: 0,
    used: 0
  },
  teamMembersLimit: {
    total: 0,
    used: 0
  },
  planUsage: [],
  workspaces: {
    sharedWorkspace: [],
    ownWorkspace: [],
    adminRights: []
  },
  invitedTeamMembers: [],
  refferalData: {
    referral_link: "",
    referral_code: "",
    referred_users: []
  },
  inviteStates: {
    email: "",
    allowPosting: false,
    sharing_workspaces: []
  },
  showOffer: false
};

const setBillingType = (state, action) => {
  state.billingType = action.payload;
};
const setShowOffer = (state, action) => {
  state.showOffer = action.payload;
};

const setWorkspaceLimits = (state, action) => {
  state.workspaceLimits = action.payload;
};

const setTeamMembersLimit = (state, action) => {
  state.teamMembersLimit = action.payload;
};

const setPlanUsage = (state, action) => {
  state.planUsage = action.payload;
};

const setWorkspaces = (state, action) => {
  state.workspaces[action.wsType] = action.payload;
};

const setInvitedTeamMembers = (state, action) => {
  state.invitedTeamMembers = action.payload;
};
const setRefferalData = (state, action) => {
  state.refferalData = action.payload;
};

const setInviteStates = (state, action) => {
  state.inviteStates[action.stateName] = action.payload;
};
const resetInviteStates = (state) => {
  state.inviteStates.email = "";
  state.inviteStates.allowPosting = false;
  state.inviteStates.sharing_workspaces = [];
};

const resetSettingStates = () => initialState;

const SettingStatesSlice = createSlice({
  name: "settingStates",
  initialState,
  reducers: {
    setBillingType,
    resetSettingStates,
    setWorkspaceLimits,
    setTeamMembersLimit,
    setPlanUsage,
    setWorkspaces,
    setInvitedTeamMembers,
    setRefferalData,
    setInviteStates,
    setShowOffer,
    resetInviteStates
  }
});

export default SettingStatesSlice.reducer;
