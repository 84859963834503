import { Crisp } from "crisp-sdk-web";

export const toggleChat = () => {
  if (Crisp.chat.isChatOpened()) Crisp.chat.close();
  else {
    Crisp.chat.open();
  }
};

export const chatConfig = () => {
  // useEffect(() => {
  try {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID);
    Crisp.setSafeMode(true);
    Crisp.chat.hide();
    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
    });
    Crisp.chat.onChatOpened(() => {
      Crisp.chat.show();
    });
    Crisp.message.onMessageReceived(() => {
      Crisp.chat.open();
    });
    // Crisp.user.setEmail(username);
  } catch (error) {
    // catch any issues with crisp
  }
  // }, []);
};
