import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, IconButton } from "./";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
// import { Height } from "@mui/icons-material";

interface CustomToggleButtonGroupProps extends ToggleButtonGroupProps {
  maxAllowed?: number;
  scrollable?: boolean;
}

const PredisToggleButtonGroup = styled(ToggleButtonGroup)<CustomToggleButtonGroupProps>(
  ({ theme, scrollable }) => ({
    position: "relative",
    boxShadow: `inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1)`,
    background: "#F2F2F5",
    borderRadius: "10px",
    padding: "2px",
    gap: "1px",
    ...(scrollable ? { whiteSpace: "nowrap" } : {}),
    display: "flex",
    width: "100%",
    ...(scrollable ? { overflowX: "auto" } : {}),
    "&::-webkit-scrollbar": {
      height: "0"
    },
    disableTouchRipple: true,
    "& .MuiToggleButtonGroup-grouped": {
      borderRadius: "10px",
      textTransform: "none",
      color: theme.palette?.fontColor?.font2,
      fontWeight: 500,
      flex: 1,
      border: "none",
      "&.Mui-selected": {
        color: theme.palette?.primarySecondary?.main,
        background: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        "&:hover": {
          background: theme.palette.common.white,
          shadowBox: "none"
        }
      }
    },
    "&.MuiButtonBase-root-MuiMenuItem-root": {
      "&.MuiButtonBase-root-MuiToggleButton-root": {
        border: "none"
      }
    }
  })
);

const StyledToggleGroup: React.FC<CustomToggleButtonGroupProps> = ({
  children,
  maxAllowed = 3,
  scrollable = true,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [startRef, inViewStart] = useInView();
  const [endRef, inViewEnd] = useInView();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollContainer = (direction: "left" | "right") => {
    const container = containerRef.current;

    if (container) {
      // alert(container.clientWidth);
      const width1 = container.clientWidth;
      const width2 = container.scrollWidth - container.clientWidth;
      // const scrollAmount = direction === "left" ? -container.clientWidth : container.clientWidth;
      // TODO  - 30 is a magic number, need to find a better way to calculate this based on left and right arrow width
      const scrollAmount =
        direction === "left"
          ? -Math.min(width1, width2) //+ 30 * 2
          : Math.min(width1, width2); //- 30 * 2;

      container.scrollBy({ left: scrollAmount, behavior: "smooth" }); //
    }
  };

  // Filter out null children
  const validChildren = React.Children.toArray(children).filter((child) => child !== null);
  const showDropDownIcon = validChildren.length > maxAllowed;

  const displayedChildren = validChildren.slice(0, maxAllowed);
  const dropdownChildren = validChildren.slice(maxAllowed);

  const commonButtonStyle = {
    position: "absolute",
    // transform: "translateY(-50%)",
    top: "1px",
    bottom: "1px",
    // zIndex: 1,
    height: "auto",
    backgroundColor: "rgba(255, 255, 255)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255)"
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <PredisToggleButtonGroup ref={containerRef} {...props} scrollable>
        <div ref={startRef} />
        {/* {displayedChildren.map((child, index) => (

            {React.cloneElement(child as React.ReactElement<any>, {
              style: { flex: 1 }
            })}
        ))} */}
        {displayedChildren}
        {showDropDownIcon && (
          <ToggleButton value='more' onClick={handleClick} style={{ flex: "0 0 10%" }}>
            <KeyboardArrowDownIcon />
          </ToggleButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ "& .MuiMenu-paper": { borderRadius: "10px" } }}
        >
          {dropdownChildren.map((child, index) => (
            <MenuItem
              key={index}
              sx={{
                "& .MuiToggleButton-root": {
                  border: "none",
                  textTransform: "none"
                }
              }}
              onClick={handleClose}
            >
              {child}
            </MenuItem>
          ))}
        </Menu>
        <div ref={endRef} />
      </PredisToggleButtonGroup>
      {!inViewEnd && scrollable && (
        <IconButton
          sx={{ ...commonButtonStyle, right: "1px", borderRadius: " 0px 10px 10px 0px" }}
          onClick={() => scrollContainer("right")}
        >
          <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
        </IconButton>
      )}
      {!inViewStart && scrollable && (
        <IconButton
          sx={{ ...commonButtonStyle, left: "1px", borderRadius: " 10px 0px 0px 10px" }}
          onClick={() => scrollContainer("left")}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: "14px" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default StyledToggleGroup;
