import queryString from "query-string";
// import { LinearProgress } from "@mui/material";
import { lazy, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
// import { Crisp } from "crisp-sdk-web";

import { lazyRetry } from "./commonUtils";
import { EmbedContextProvider } from "./embed/embedContext";
import { SEMRUSH_HOST } from "./utils/constants";

import axios from "axios";

import { QueryClientProvider } from "react-query";
import { LazyLoader } from "./LazyLoader";
import { PermissionProvider } from "./context/permissions/PermissionContext";
// import Embed from "./embed/route";

import { queryClient } from "./indexUtils";
import { API_ADDRESS, setupInterceptors } from "./services/api";
import { chatConfig } from "./utils/chat-support-utils";
import { getListenerParent } from "./utils/common";
import { localStorageSafe } from "./utils/localStorageUtils";
import { getPath } from "./utils/navigation";

const EcomConfirm = lazy(() =>
  lazyRetry(() => import("./components/Workspace/WorkspaceSections/EcomConfirm"))
);
const EcomPaymentConfirm = lazy(() =>
  lazyRetry(() => import("./components/Workspace/WorkspaceSections/EcomPaymentConfirm"))
);
//const Woocommerce = lazy(() => lazyRetry(() => import("./components/StoreComponents/Woocommerce")));
const Ecwid = lazy(() => lazyRetry(() => import("./components/StoreComponents/Ecwid")));
const Wix = lazy(() => lazyRetry(() => import("./components/StoreComponents/Wix")));
const AppRouter = lazy(() => lazyRetry(() => import("./AppRouter")));
const OauthRouter = lazy(() => lazyRetry(() => import("./OauthRouter")));
const Embed = lazy(() => lazyRetry(() => import("./embed/route")));
const LoginSemrush = lazy(() => lazyRetry(() => import("./components/SignIn/LoginSemrush")));

const NotFound = lazy(() => lazyRetry(() => import("./components/NotFound")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./components/ForgotPassword/ForgotPassword"))
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./components/ResetPassword/ResetPassword"))
);
const UnsubEmail = lazy(() => lazyRetry(() => import("./components/UnsubEmail/UnsubEmail")));
const SignUp = lazy(() => lazyRetry(() => import("./components/SignUp")));

const SignIn = lazy(() => lazyRetry(() => import("./components/SignIn/SignIn")));

const ShopifyConfirm = lazy(() => lazyRetry(() => import("./components/Settings/ShopifyConfirm")));
const GptStore = lazy(() => lazyRetry(() => import("./components/gptStore/gptStore")));
const ShopifyAsk = lazy(() => lazyRetry(() => import("./components/ShopifyAsk/ShopifyAsk")));
const ShareAuth = lazy(() => lazyRetry(() => import("./components/SharePreview/ShareAuth")));

const OnboardingWrapper = lazy(() =>
  lazyRetry(() => import("./components/Onboarding2/OnboardingWrapper"))
);

const params = queryString.parse(window.location.search);
const embedAppId = params.app_id || localStorageSafe("getItem", "appId");

function MainRouter() {
  const { isWebView, createPostOpen } = useSelector((state) => state.uiStates);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useMemo(() => {
    // setup interceptors on load
    setupInterceptors();
    // set global axios url
    axios.defaults.baseURL = API_ADDRESS;
  }, []);

  let search_paramas = new URLSearchParams(window.location.search);
  const { editOpen, previewOpen } = useSelector((state) => state.getPosts);

  const jwtToken = localStorageSafe("getItem", "jwt");

  const editOpenRef = useRef(editOpen);
  const previewOpenRef = useRef(previewOpen);
  const createPostRef = useRef(createPostOpen);

  useEffect(() => {
    editOpenRef.current = editOpen;
    previewOpenRef.current = previewOpen;
    createPostRef.current = createPostOpen;
  }, [editOpen, previewOpen, createPostOpen]);

  // useEffect(() => {
  //   we_track("sidebar_navigate", { path: location.pathname });
  // }, [location]);

  const handleBackGesture = ({ data }) => {
    try {
      if (data && data?.startsWith("event")) {
        const [_, evName] = data.split("||");
        if (evName === "backPressed") {
          // LISTENER IS NOT REACTIVE TOWARDS STATE. WILL NOT GET UPDATED STATE VARIABLE VALUE
          if (previewOpenRef.current) {
            dispatch({ type: "getPosts/setPreviewOpen", payload: false });
          } else if (editOpenRef.current) {
            dispatch({ type: "getPosts/setEditOpen", payload: false });
          } else if (createPostRef.current) {
            dispatch({
              type: "uiStates/setCreatePostOpen",
              payload: false
            });
          } else {
            navigate(-1);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    isWebView && localStorageSafe("setItem", "isWebview", true);
    isWebView && getListenerParent().addEventListener("message", handleBackGesture);
    return () => {
      isWebView && getListenerParent().removeEventListener("message", handleBackGesture);
    };
  }, []);

  useEffect(() => {
    chatConfig();
  }, []);

  const currentURL = new URL(window.location.href);

  const EcomNavigator = () => {
    const url = window.location.pathname;
    const match = url.match(/\/ecom-login\/([^?/]+)/);

    switch (match?.[1]) {
      case "wix":
        return <Wix />;
      // case "woocommerce":
      //   return <Woocommerce />;
      case "ecwid":
        return <Ecwid />;
      default:
        return <Navigate to='/' />;
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <PermissionProvider>
        <EmbedContextProvider embedAppId={embedAppId}>
          <Routes>
            {/* Auth Routes */}
            <Route
              path='/oauth/*'
              element={
                <LazyLoader>
                  <OauthRouter />
                </LazyLoader>
              }
            />
            <Route
              path='/shopify_confirm'
              element={
                <LazyLoader>
                  <ShopifyConfirm />
                </LazyLoader>
              }
            />
            <Route
              path='/gpt_store'
              element={
                <LazyLoader>
                  <GptStore />
                </LazyLoader>
              }
            />

            <Route
              path='/'
              element={
                currentURL.host === SEMRUSH_HOST ? (
                  // for semrush
                  <LazyLoader>
                    <LoginSemrush />
                  </LazyLoader>
                ) : !!jwtToken && search_paramas.get("shop") === null ? (
                  // else autologin based on whether jwt is present
                  // Add reactnative.postmsg of "userData" on autologin as well
                  <Navigate
                    to={(() => {
                      if (localStorageSafe("getItem", "user_has_posts") !== "true")
                        return "/onboarding";
                      else return getPath("/dashboard");
                    })()}
                  />
                ) : (
                  // else signin page
                  <LazyLoader>
                    <SignIn />
                  </LazyLoader>
                )
              }
            />
            <Route
              path='/ecom-login/:store'
              element={
                <LazyLoader>
                  <EcomNavigator />
                </LazyLoader>
              }
            />
            <Route
              path='/ecom_confirm'
              element={
                <LazyLoader>
                  <EcomConfirm />
                </LazyLoader>
              }
            />
            <Route
              path='/ecom_payment_confirm'
              element={
                <LazyLoader>
                  <EcomPaymentConfirm />
                </LazyLoader>
              }
            />
            <Route
              path='/forgot-password'
              element={
                <LazyLoader>
                  <ForgotPassword />
                </LazyLoader>
              }
            />
            <Route
              path='/reset-password'
              element={
                <LazyLoader>
                  <ResetPassword />
                </LazyLoader>
              }
            />
            <Route
              path='/email_unsubscribe'
              element={
                <LazyLoader>
                  <UnsubEmail />
                </LazyLoader>
              }
            />
            <Route
              path='/shopify_ask'
              element={
                <LazyLoader>
                  <ShopifyAsk />
                </LazyLoader>
              }
            />
            {currentURL.host !== SEMRUSH_HOST && (
              <Route
                path='/signup'
                element={
                  <>
                    <LazyLoader>
                      <SignUp />
                    </LazyLoader>
                  </>
                }
              />
            )}
            <Route path='app/*' element={<AppRouter />} />
            <Route
              path='embed/*'
              element={
                <LazyLoader>
                  <Embed />
                </LazyLoader>
              }
            ></Route>
            <Route
              path='/igpost/get_post_previews/'
              element={
                <LazyLoader>
                  <ShareAuth />
                </LazyLoader>
              }
            />
            <Route
              path='*'
              element={
                <LazyLoader>
                  <NotFound />
                </LazyLoader>
              }
            ></Route>
            <Route
              path='/onboarding'
              element={
                <LazyLoader>
                  <OnboardingWrapper />
                </LazyLoader>
              }
            />
          </Routes>
        </EmbedContextProvider>
      </PermissionProvider>
    </QueryClientProvider>
  );
}

export default MainRouter;
